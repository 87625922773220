<template>
  <div>
    <div class="flex flex-row items-end justify-between">
      <div class="flex flex-row">
        <InputField
          :placeholder="inputPlaceholder"
          :label="inputLabel"
          :onChange="setKeyword"
          leftIcon
          class="mb-2 w-inputField"
          data-testid="filter-field"
        />
        <Select
          :options="this.statusOptions"
          :onSelect="setStatusFilter"
          :value="statusFilter"
          :preselectFirst="true"
          label="Status"
          objects
          class="ml-2 min-w-150 h-40"
        />
      </div>
      <div class="flex flex-row space-x-2">
        <div class="mb-2">
          <Button
            text="Invite to Platform"
            type="secondary"
            size="medium"
            data-testid="invite-button"
            :iconLeft="email"
            @click.native="inviteToPlatform"
          />
        </div>
        <div class="mb-2">
          <Button
            text="Get Upload Template (.CSV)"
            type="secondary"
            size="medium"
            @click.native="downloadTemplate"
            data-testid="download-button"
          />
        </div>
        <AddNewButton
          :callback="updateList"
          :withRole="true"
          class="z-10"
          data-testid="addnew-button"
        />
      </div>
    </div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ mappedList.length }} out of {{ this.usersCount }} people
    </p>
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 px-2 rounded">
        <button
          class="flex flex-row items-center focus:outline-none w-tableIndex"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
        </button>
        <div class="flex flex-grow w-1/5">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="() => sort('name', 0)"
            data-testid="header-0"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">
              NAME {{ this.$screen.breakpoint.xlAndDown ? "& EMAIL" : "" }}
            </p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div
          class="flex w-1/6 -ml-2"
          v-if="!this.$screen.breakpoint.xlAndDown"
        >
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="() => sort('email', 1)"
            data-testid="header-0"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">EMAIL</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/6">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('jobTitle', 2)"
            data-testid="header-1"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/6">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('role', 3)"
            data-testid="header-1"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">USER ROLE</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/10">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('status', 4)"
            data-testid="header-1"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">STATUS</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[4] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-row items-center focus:outline-none w-100">
          <p class="text-sm-2 text-grey-light mr-0.4">ACTIONS</p>
        </div>
      </div>
      <div>
        <VirtualList
          style="max-height: 360px; overflow-y: auto"
          :data-key="'id'"
          :data-sources="mappedList"
          :data-component="itemComponent"
          :extra-props="{ manage: doManage }"
          @tobottom="triggerFetch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import AddNewButton from "../../../AdminAssessments/components/AddNewButton";
import email from "@/assets/img/icons/email.svg";
import { mapActions } from "vuex";

export default {
  name: "PeopleTable",
  components: { VirtualList, AddNewButton },
  props: {
    inputLabel: { type: String, default: "Filter by" },
    inputPlaceholder: {
      type: String,
      default: "Search by name, status or job title",
    },
    id: { type: Number, default: 1 },
  },
  data: () => ({
    email,
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1, 1],
    mappedList: [],
    selectAll: false,
    pageNr: 1,
    sorting: null,
    usersCount: null,
    statusFilter: null,
    statusOptions: [
        { label: "All Statuses", id: -1 },
        { label: "Inactive", id: 0 },
        { label: "Active", id: 1 },
        { label: "Invited", id: 2 },
        { label: "Not Invited", id: 3 },
        { label: "Deleted", id: 4 }
      ],
  }),
  watch: {
    keyword() {
      this.updateList(true);
    },
    statusFilter() {
      this.updateList(true);
      this.$emit('onChangeStatusFilter', this.statusFilter.id)
    },
  },
  methods: {
    ...mapActions({
      getPeople: "people/getPeople",
      setSelectedUser: "companies/setSelectedUser",
      downloadTemplate: "utils/downloadTemplate",
      setSelectedUsersForInvite: "people/setSelectedUsersForInvite",
    }),
    setStatusFilter(value) {
      this.statusFilter = value;
    },
    async doManage(user) {
      await this.setSelectedUser({ user: user });
      this.$router.push(`/people/${user.id}`);
    },
    async sort(key, index) {
      this.resetRotationMatrix(index);
      this.setRotation(index);
      switch (key) {
        case "name":
          this.sorting = {firstName: this.rotationMatrix[0] == -1 ? 1 : 2}
          break;
        case "email":
          this.sorting = {email: this.rotationMatrix[1] == -1 ? 1 : 2}
          break;
        case "jobTitle":
          this.sorting = {jobTitle: this.rotationMatrix[2] == -1 ? 1 : 2}
          break;
        case "role":
          this.sorting = {role: this.rotationMatrix[3] == -1 ? 1 : 2}
          break;
        case "status":
          this.sorting = {status: this.rotationMatrix[4] == -1 ? 1 : 2}
          break;
        case "role":
          const getRolesString = (roles) => roles.map(x => x.name).join(', ')
          this.mappedList.sort(
              (a, b) =>
                this.rotationMatrix[index] * (getRolesString(a.roles).localeCompare(getRolesString(b.roles)))
            );
          break;
        case "status":
          this.mappedList.sort(
              (a, b) =>
                this.rotationMatrix[index] * (a.status - b.status)
            );
          break;
        default:
          break;
      }
      this.updateList(true)
    },
    resetRotationMatrix(idx) {
      this.rotationMatrix.map((_, index, array) =>
        index == idx ? null : (array[index] = 1)
      );
    },
    setRotation(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx == index ? -1 * item : 1
      );
    },
    setKeyword: debounce(function(value) {
      this.keyword = value;
      this.pageNr = 1;
    }, 500),
    triggerFetch() {
      if (this.pageNr < Math.ceil(this.usersCount / 10)) {
        this.pageNr++;
        this.selectAll ? this.toggleAll() : null;
        this.updateList();
      }
    },
    async updateList(reset) {
      if (reset) {
        this.mappedList = [];
        this.pageNr = 1;
      }
      let newContent = await this.getPeople({
        keyword: this.keyword.toLowerCase(),
        pageNr: this.pageNr,
        sorting: this.sorting,
        allowInviteInactiveUsers: true,
        status: this.statusFilter.id,
      }).then((rsp) => {
        this.usersCount = rsp.data.totalCount;
        return rsp.data.data;
      });
      let map = newContent.map((item, idx) => ({
        ...item,
        isSelected: false,
        index: (this.pageNr - 1) * 10 + idx,
      }));
      this.mappedList = [...this.mappedList, ...map];
    },
    toggleItem(index) {
      this.mappedList[index].isSelected = !this.mappedList[index].isSelected;
    },
    toggleAll() {
      this.selectAll = !this.selectAll;
      this.mappedList.map((item) => (item.isSelected = this.selectAll));
    },
    inviteToPlatform() {
      // this.setSelectedUsersForInvite([]);
      this.$router.push("/people/invite");
    },
  },
};
</script>
