<template>
  <div>
    <h1
      class="text-xl text-grey-light font-medium font-headers mb-3 font-bold text-grey-dark-3"
      data-testid="title-header"
    >
      People
    </h1>
    <div class="bg-white shadow mt-3 relative p-3 rounded">
      <Button
        class="absolute right-2"
        text="Export Data"
        type="primary"
        size="medium"
        :iconLeft="downloadIcon"
        @click.native="exportAllUsers"
      />
      <p class="font-barlow text-mdl font-bold text-grey-light mb-2">
        Manage Your Company Users
      </p>
      <div class="xl:w-3/6 w-full mt-4">
        <p class="text-mdh text-grey-dark-1">
          By managing you either change the user roles or deactivate each person
          from the list below. Also, you can upload a list of new employees. Please
          use the template below to provide the required set of data. You cannot
          upload lists that do not follow this template.
        </p>
        <p class="text-mdh text-grey-dark-1 font-bold">Statuses:</p>
        <p class="text-mdh text-grey-dark-1">
          <span class="text-grey-medium-2">Not invited</span> = users that have
          not been invited to the platform
        </p>
        <p class="text-mdh text-grey-dark-1">
          <span class="text-yellow-medium">Invited</span> = users that have been
          sent emails to join the platform and have not created an account
        </p>
        <p class="text-mdh text-grey-dark-1">
          <span class="text-green-2">Active</span> = registered users
        </p>
        <p class="text-mdh text-grey-dark-1">
          <span class="text-red">Deactivated</span> = user accounts that have
          been closed by admins
        </p>
        <p class="text-mdh text-grey-dark-1">
          <span class="text-purple">Deleted</span> = user accounts that have
          personal information removed
        </p>
      </div>
      <div class="mt-3">
        <PeopleTable data-testid="people-table" @onChangeStatusFilter="onChangeStatusFilter"/>
      </div>
    </div>
  </div>
</template>

<script>
import PeopleTable from '../components/PeopleTable';
import downloadIcon from "@/assets/img/icons/download.svg";
import { mapState, mapActions } from "vuex";

export default {
  name: 'PeopleView',
  components: {
    PeopleTable,
  },
  data: () => ({
    downloadIcon,
    stausFilter: null,
  }),
  computed: {
    ...mapState({
      currentCompany: (state) => state.companies.currentCompany,
    }),
  },
  methods: {
    ...mapActions({ 
      exportUsers: "companyResults/exportUsers"
    }),
    exportAllUsers() {
      this.exportUsers({organizationId: this.currentCompany.id, status: this.stausFilter});
    },
    onChangeStatusFilter(value) {
      this.stausFilter = value
    }
  }
};
</script>
